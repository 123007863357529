import React from 'react';
import  "tabulator-tables/dist/css/tabulator_semanticui.css";
import parse from 'html-react-parser';
import TabulatorSoftdata from './TabulatorSoftdata';
import TableToExcel from './TableToExcel';
import { getTextWidth } from "get-text-width";


class DatatableReports extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            studyName: props.studyName,
            nomeQuadro: props.nomeQuadro,
            dataTypeName: props.dataTypeName,
            column: props.column,            
            data: props.data,
            media: props.media,
            nps: props.nps,
            textoBase: props.textoBase,
            textoNota: props.textoNota,
            columnDefs: [],
            rowData: [],
            columns: [],
            precision: props.precision,
            displayTable:  false,
            height: 0,
            tabulator: null,         
            topColumn: "",   
        };

        this.initializeState = this.initializeState.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.componentDidUpdate = this.componentDidUpdate.bind(this);
        this.buildTableData = this.buildTableData.bind(this);
        this.setColumnDefs = this.setColumnDefs.bind(this);
    }

    setColumnDefs() {
            var rows=this.state.data;
            var columnsNoTotal=rows[0].reportResultsByColumn.filter((c)=>c.coluna!=='TOTAL');
            var topColumn = rows[0].coluna;
            this.setState({topColumn: topColumn});
            var columnArray = [];
            var rowBase = this.state.rowData.find((r)=>r.lineName==='Base');
            var columnMinWidth = 0;
            for (let i=0;i<columnsNoTotal.length;i++){
                var columnWidthLength = getTextWidth(rowBase["col" + (i + 2)]);
                if (columnWidthLength>columnMinWidth){
                    columnMinWidth = columnWidthLength;
                }
            }
                    
            var colTitlesBR = [];
            for (let i=0;i<columnsNoTotal.length;i++){
                var column = columnsNoTotal[i].coluna;            
                var prevS = ''; 
                while(prevS !== column){
                    prevS = column; column = column.replace(/([0-9]+) ([0-9]+)/, '$1_$2');
                }
                var pos = 0;
                var finalColumnString="";
                var currentLineLength=0;
                var wordLength=0;
                var auxWord="";
                var colAux;
                while (column.length>0){                
                    pos = column.indexOf(" ");
                    if (pos < 14) {
                        var auxLength=0;
                        var auxLine="";
                        var auxLineWidth=0;
                        while ((pos!==-1) && (pos<14) && (auxLength<14)){
                            auxLine += column.substring(0,pos)+" ";
                            auxLength += pos+1;
                            auxLineWidth=getTextWidth(auxLine);
                            if (auxLineWidth>columnMinWidth){
                                columnMinWidth=auxLineWidth;
                            }
                            column = column.substring(pos+1,column.length);
                            pos = column.indexOf(" ");                                                
                        }
                        if (pos===-1||auxLength>=14){
                            auxLineWidth=getTextWidth(auxLine);
                            if (auxLineWidth>columnMinWidth){
                                columnMinWidth=auxLineWidth;
                            }                        
                            auxLineWidth=getTextWidth(column);
                            if (auxLineWidth>columnMinWidth){
                                columnMinWidth=auxLineWidth;
                            } 
                            if (auxLength>=14){
                                auxLine+="<BR/>";
                            }
                            auxLine += column;
                           
                            
                        }
                        finalColumnString+=auxLine;
                        break;
                        
                    } else if (pos>=14){
                        finalColumnString += column.substring(0,pos)+"<BR/>";      
                        auxWord = finalColumnString.replaceAll("<BR/>","_");
                        wordLength = getTextWidth(auxWord.substring(0,pos));
                        if (wordLength>columnMinWidth){
                            columnMinWidth = wordLength;
                        }
                        column = column.substring(pos+1,column.length);
                    }       
                }
                colTitlesBR[i] = finalColumnString.replaceAll('_','&nbsp;');
            }
            columnMinWidth = columnMinWidth*0.6;
            for (let i=0;i<columnsNoTotal.length;i++){                        
                columnArray[i] = { title: colTitlesBR[i], 
                                   field: "col"+(i+2), headerSort: false, 
                                   headerHozAlign:"center", 
                                   hozAlign: "center",     
                                   minWidth: columnMinWidth,
                                   //width: columnMinWidth,
                                    };
            }        
    
            var maxLineLenght=Math.max(...this.state.rowData.map(l=>getTextWidth(l.lineName)));
            maxLineLenght = maxLineLenght*0.75;
    
            var columnDefs=[
                { title: "", field: "lineName", headerSort: false,
                   
                 minWidth: maxLineLenght, frozen: true }
            ];
            
    
            var columnTotal = rows[0].reportResultsByColumn.find((c)=>c.coluna==='TOTAL');
            var colIndex=1;
            if (typeof columnTotal!=='undefined'){
                columnMinWidth = getTextWidth(rowBase["totalCol"]);
                var totalWidth=getTextWidth("Total");
                if (totalWidth>columnMinWidth){
                    columnMinWidth=totalWidth;
                }
                columnDefs[colIndex] = { title: "Total", 
                                          field: "totalCol", 
                                          headerSort: false, 
                                          headerHozAlign:"center", 
                                          hozAlign:"center",          
                                          minWidth: totalWidth,       
                                          width: columnMinWidth,                               
                                          frozen: true 
                                          };            
            }
    
            if (typeof topColumn==='undefined'){
                topColumn="";
            }
    
            if (columnArray.length>0){
                columnDefs[colIndex+1] =  {
                    title:`${topColumn}`,
                    headerHozAlign:"center",
                    columns: columnArray,       
                    formatter:"textarea",
                    width: "100%", // Ensure the title column takes up the full width
                    minWidth: columnMinWidth * 1.5, // Allow some flexibility in width to fit the text                    
                };
            }
    
            this.setState({columnDefs: columnDefs});
            this.setState({columns: columnArray});
            return columnDefs;
        }    
    
    
    buildTableData(){      
        var rows=this.state.data;  
        var rowsAux=[];
        
        for (let i=0;i<rows.length;i++){        
            const newRow = { "id": i + 1 };
            newRow["lineName"] = rows[i].linha;
            var columnsAux=rows[i].reportResultsByColumn;
            var columnTotal=columnsAux.find((c)=>c.coluna==='TOTAL');
            if (typeof columnTotal!=='undefined'){
                if (this.state.dataTypeName==='Valores médios') {
                    newRow["totalCol"]=Intl.NumberFormat('pt-PT',{useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2}).format(parseFloat(columnTotal.pv).toFixed(2));
                } else {
                    newRow["totalCol"]=Intl.NumberFormat('pt-PT',{useGrouping: true, minimumFractionDigits: 1}).format(parseFloat(columnTotal.pv).toFixed(1));
                }
            }
            var columnAuxNoTotal=columnsAux.filter((c)=>c.coluna!=='TOTAL');
            for (let j=0;j<columnAuxNoTotal.length;j++){
                var colFieldIndex = "col" + (j + 2);
                var value = 0;
                if (this.state.dataTypeName==='Percentagens verticais (%)' || this.state.dataTypeName==='Valores médios'){
                    value = columnAuxNoTotal[j].pv;
                } else if (this.state.dataTypeName==='Percentagens horizontais (%)'){
                    value = columnAuxNoTotal[j].ph;
                } else {
                    value = columnAuxNoTotal[j].valorBase;
                }
                if (this.state.dataTypeName==='Valores médios') {
                    var result = parseFloat(value).toFixed(2);
                    newRow[colFieldIndex] = Intl.NumberFormat('pt-PT',{useGrouping: true,minimumFractionDigits: 2, maximumFractionDigits: 2}).format(result);
                } else {
                    var result = parseFloat(value).toFixed(this.state.precision);
                    newRow[colFieldIndex] = Intl.NumberFormat('pt-PT',{useGrouping: true,minimumFractionDigits: 1}).format(result);
                }
            }
            rowsAux[i]=newRow;
        }
        var index=rowsAux.length;
        var row0 = this.state.data[0];
        var colsRow0 = row0.reportResultsByColumn;
        var colTotalRow0 = colsRow0.find((c)=>c.coluna==='TOTAL');
        var colsNoTotalRow0 = colsRow0.filter((c)=>c.coluna!=='TOTAL');
        var rowBase = { "id": "base" };
        rowBase["lineName"] = "Base";
        if (typeof colTotalRow0!=='undefined'){
            rowBase["totalCol"] = Intl.NumberFormat('pt-PT',{useGrouping: true,}).format(colTotalRow0.base);
        }
        for (let i=0;i<colsNoTotalRow0.length;i++){
            var colFieldIndex = "col" + (i + 2);
            var base = colsNoTotalRow0[i].base;
            var result = Intl.NumberFormat('pt-PT',{useGrouping: true,}).format(base);
            rowBase[colFieldIndex] = result;
        }
        rowsAux[index]=rowBase;
        index++;
        if (this.state.media){
            var rowMedia={ "id": "media" };
            rowMedia["lineName"] = "Média";
            if (typeof colTotalRow0!=='undefined'){
                rowMedia["totalCol"] = Intl.NumberFormat('pt-PT',{useGrouping: true, minimumFractionDigits: 2}).format(parseFloat(colTotalRow0.media).toFixed(2));
            }
            for (let i=0;i<colsNoTotalRow0.length;i++){
                var colFieldIndex = "col" + (i + 2);
                var media = colsNoTotalRow0[i].media;
                var result = Intl.NumberFormat('pt-PT',{useGrouping: true, minimumFractionDigits: 2}).format(parseFloat(media).toFixed(2));
                rowMedia[colFieldIndex] = result;
            }
            rowsAux[index]=rowMedia;
        } else if (this.state.nps){
            var rowNps={ "id": "nps" };
            rowNps["lineName"] = "Net Promoter Score";
            if (typeof colTotalRow0!=='undefined'){
                rowNps["totalCol"] = Intl.NumberFormat('pt-PT',{useGrouping: true,}).format(parseFloat(colTotalRow0.nps).toFixed(0));
            }
            for (let i=0;i<colsNoTotalRow0.length;i++){
                var colFieldIndex = "col" + (i + 2);
                var nps = colsNoTotalRow0[i].nps;
                var result = Intl.NumberFormat('pt-PT',{useGrouping: true,}).format(parseFloat(nps).toFixed(0));
                rowNps[colFieldIndex] = result;
            }
            rowsAux[index]=rowNps;
        }

        this.setState({rowData: rowsAux});
        var totalRows = rowsAux.length;     
    }

    initializeState = async () => {
        await this.props.data;
        this.state = {
            studyName: this.props.studyName,
            nomeQuadro: this.props.nomeQuadro,
            dataTypeName: this.props.dataTypeName,
            column: this.props.column,            
            data: this.props.data,
            media: this.props.media,
            nps: this.props.nps,
            textoBase: this.props.textoBase,
            textoNota: this.props.textoNota,
            columnDefs: [],
            rowData: [],
            columns: [],
            precision: this.props.precision,
            displayTable: false,
            height: 0,
            tabulator: null,         
            topColumn: "",   
        };
        this.buildTableData();
        this.setColumnDefs();
        
        if (typeof this.state.data!=='undefined' && this.state.rowData.length>0){
            this.setState({displayTable: true});
        }
        
    }

    componentDidUpdate = async (prevProps) => {
        await this.props.data;
        if (typeof this.props.data !== 'undefined' && this.props.data !== prevProps.data) {         
            this.setState({nps: this.props.nps});
            this.setState({media: this.props.media});
            this.initializeState();            
        }        
    }

    componentDidMount = async () => {
        await this.props.data;
        this.initializeState();
    }

    render() {
        return (
           <div id="tabulator-div-parent">            
            {this.state.displayTable  &&
                <div>
                    <TableToExcel 
                        title1={this.state.studyName}
                        title2={this.state.nomeQuadro}
                        dataTypeName={this.state.dataTypeName}
                        columns={this.state.columns}
                        rowData={this.state.rowData}    
                        textoBase={this.state.textoBase} 
                        textoNota={this.state.textoNota}
                        topColumn={this.state.topColumn}
                        filtrosArray={[]}
                    />
                </div>
                }
            {this.state.displayTable  && 
                <div>
                    <TabulatorSoftdata 
                        rowData={this.state.rowData}
                        columnDefs={this.state.columnDefs}
                        columns={this.state.columns} />
                    
                </div>
            }
           
            {this.state.displayTable && this.state.textoBase!=="" && 
                <div style={{width: "900px", marginLeft:"auto",marginRight:"auto",textAlign:"left"}}>                                
                    <span style={{fontSize: "14px"}}><strong>Base:</strong> {parse(this.state.textoBase)}</span>                
                </div>
            }
            {this.state.displayTable && this.state.textoNota!=="" && 
                <div style={{width: "900px", marginLeft:"auto",marginRight:"auto",textAlign:"left"}}>         
                    <span style={{fontSize: "14px"}}><strong>Nota:</strong> {parse(this.state.textoNota)}</span>
                </div>
            }
            
            {
                this.state.rowData.length===0 && 
                    <div>
                        <center>
                            <h4>Não existem dados.</h4>
                        </center>
                    </div>
            }
            <br/>
            <hr/>
            <br/>
            </div>
        );
    }

}

export default DatatableReports;