import React from 'react';
import './Tabs.css';
import LinesChart from '../Charts/LinesChart';
import DatatableTables from '../Datatables/DatatableTables';
import GetDataEvolutivo from './GetDataEvolutivo';
import ProcessarFiltros from './ProcessarFiltros';
import TablesNoData from './TablesNoData';
import SpinnerContent from '../common/components/SpinnerContent';

class EvolutiveStudytData extends React.Component {
    constructor(props) {
        super(props);
        const studyName = props.studyName;
        const waveName = props.waveName;
        const waveID = props.waveID;
        const lineName = props.lineName;
        const selectedVagasEvolutivo = props.selectedVagasEvolutivo;
        const filters = props.filters;

        this.state = {
            studyName: studyName,
            waveName: waveName,
            waveID: waveID,
            lineName: lineName,
            selectedVagasEvolutivo: selectedVagasEvolutivo,
            filters: filters,
            activeTab: 'tab1',
            studies: null,
            filtersArray: [],
            verticalTitlesTable: [],
            horizontalTitlesTable: [],
            rowDataVertical: [],
            rowDataAbsolute: [],
            lineChartData: [],
            lineChartDataMedia: [],
            lineChartDataNPS: [],
            firstAssociatedVariable: "",
            textoBase: "",
            textoNota: "",
            hasMedia: false,
            rowDataMedia: [],
            hasNPS: false,
            rowDataNPS: [],
            gotData: false,
        }

        this.getVerticalTitleTable = this.getVerticalTitleTable.bind(this);
        this.getHorizontalTitleTable = this.getHorizontalTitleTable.bind(this);
        this.getRows = this.getRows.bind(this);
        this.getRowsMedia = this.getRowsMedia.bind(this);
        this.getRowsNPS = this.getRowsNPS.bind(this);
        this.initializeState = this.initializeState.bind(this);
        this.buildLineChartData = this.buildLineChartData.bind(this);
        this.getColumnNames = this.getColumnNames.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.componentDidUpdate = this.componentDidUpdate.bind(this);
        this.buildChartDataMedia = this.buildChartDataMedia.bind(this);
        this.buildChartDataNPS = this.buildChartDataNPS.bind(this);
        this.lastRequestId = null;
    }

    getVerticalTitleTable(studies) {
        var verticalTitlesTableArray = [];

        for (let i = 0; i < studies.length; i++) {
            verticalTitlesTableArray[i] = studies[i].evolVagaId;
        }
        return verticalTitlesTableArray;
    }

    getColumnNames(aggregatedResults, lineTotalsLength) {
        let i = 0;
        var columnNames = [];
        var index = 0;
        var previousColumn="";
        while (i < aggregatedResults.length) {
            if (aggregatedResults[i].nameColumn!==previousColumn){
                columnNames[index] = aggregatedResults[i].nameColumn;
                previousColumn=columnNames[index];
                index++;
            }            
            i++;            
        }
        return columnNames;
    }

    getHorizontalTitleTable(studies) {
        var horizontalTitlesTableArray = [];
        var mainStudy = null;
        for (let i = 0; i < studies.length; i++) {
            if (studies[i].waveName === this.state.waveName) {
                mainStudy = studies[i];
                this.setState({ firstAssociatedVariable: mainStudy.studyResults.firstAssociatedVariable });

                if (typeof mainStudy.studyResults.textoBase === 'undefined') {
                    this.setState({ textoBase: "" });
                } else {
                    this.setState({ textoBase: mainStudy.studyResults.textoBase });
                };

                var textoBase = this.state.textoBase;
                var baseAmostral = 30;
                var univVal = mainStudy.univVal;
                if (typeof univVal !== 'undefined' && univVal !== null && univVal !== 0) {
                    var totalSum = mainStudy.studyResults.totalSumWeithingFactorsFirstVariable;
                    if (totalSum / univVal !== 1) {
                        //var calcVal = totalSum / univVal * 100;
                        var answers = mainStudy.studyResults.answersNumber;
                        if (answers < baseAmostral) {
                            textoBase += " <br>Observações:<font color=\"red\"> Base amostral reduzida</font>."
                        }
                    }
                }
                this.setState({ textoBase: textoBase });
                if (typeof mainStudy.studyResults.evolTextoNota === 'undefined') {
                    this.setState({ textoNota: "" });
                } else {
                    this.setState({ textoNota: mainStudy.studyResults.evolTextoNota });
                }
                break;
            }
        }
        if (mainStudy !== null) {
            var params = mainStudy.studyResults.params;
            var npsMedia = false;
            var lineTotalsArray = Object.keys(mainStudy.studyResults.lineTotals).map(key => mainStudy.studyResults.lineTotals[key]);
            var lineTotalsLength = lineTotalsArray.length;
            if (typeof params !== 'undefined' && params[0] !== 'undefined') {
                npsMedia = true;
                if (params[0] === 'NPS') {
                    this.setState({ hasNPS: true });
                    this.setState({ hasMedia: true });
                } else if (params[0] === 'MEDIA') {
                    this.setState({ hasMedia: true });
                }
            }
            if (npsMedia) {
                horizontalTitlesTableArray =
                    this.getColumnNames(mainStudy.studyResults.aggregatedResults[0].aggregatedResults,
                        lineTotalsLength);
            } else if (typeof mainStudy.studyResults.lineTotalsWithLineNames !== 'undefined') {
                horizontalTitlesTableArray = Object.keys(mainStudy.studyResults.lineTotalsWithLineNames).map(key => mainStudy.studyResults.lineTotalsWithLineNames[key]);
            } else {
                var aggResults = mainStudy.studyResults.aggregatedResults[0].aggregatedResults;

                for (let i = 0; i < lineTotalsLength; i++) {
                    horizontalTitlesTableArray[i] = aggResults[i].nameLine;
                }
            }
        }
        return horizontalTitlesTableArray;
    }

    getRows(totalLines, horizontalTitlesTable, verticalTitlesTable, studies) {
        var rowsVertical = [];
        var rowsAbsolute = [];

        for (let i = 0; i < totalLines; i++) {
            var lineTitle = horizontalTitlesTable[i];
            var newRowVertical = { "id": i + 1, "lineName": lineTitle };
            var newRowAbsolute = { "id": i + 1, "lineName": lineTitle };

            for (let n = 0; n < verticalTitlesTable.length; n++) {

                var columnTitle = verticalTitlesTable[n];
                var colFieldIndex = "col" + (n + 2);
                newRowVertical[colFieldIndex] = "";
                newRowAbsolute[colFieldIndex] = "";
                for (let j = 0; j < studies.length; j++) {
                    if (studies[j].evolVagaId === columnTitle) {
                        var lineTotalsWithNames = [];
                        if (typeof studies[j].studyResults.lineTotalsWithLineNames !== 'undefined') {
                            lineTotalsWithNames = Object.keys(studies[j].studyResults.lineTotalsWithLineNames).map(key => studies[j].studyResults.lineTotalsWithLineNames[key]);
                        } else {
                            var aggResults = studies[j].studyResults.aggregatedResults[0].aggregatedResults;
                            for (let i = 0; i < aggResults.length; i++) {
                                lineTotalsWithNames[i] = aggResults[i].nameLine;
                            }
                        }
                        var lineVerticalPercentages = Object.keys(studies[j].studyResults.lineVerticalPercentages).map(key => studies[j].studyResults.lineVerticalPercentages[key]);
                        var lineTotals = Object.keys(studies[j].studyResults.lineTotals).map(key => studies[j].studyResults.lineTotals[key]);
                        for (let k = 0; k < lineTotalsWithNames.length; k++) {
                            if (lineTotalsWithNames[k] === lineTitle) {
                                if (typeof lineVerticalPercentages[k] !== 'undefined') {
                                    var vp = lineVerticalPercentages[k];
                                    vp = parseFloat(vp).toFixed(1);
                                    var result = Intl.NumberFormat('pt-PT', { useGrouping: true, minimumFractionDigits: 1}).format(vp);
                                    newRowVertical[colFieldIndex] = result;
                                }
                                if (typeof lineTotals[k] !== 'undefined') {
                                    var abs = lineTotals[k];
                                    abs = parseFloat(abs).toFixed(0);
                                    result = Intl.NumberFormat('pt-PT', { useGrouping: true, }).format(abs);
                                    newRowAbsolute[colFieldIndex] = result;
                                }
                                break;
                            }
                        }
                        break;

                    }
                }
            }

            rowsVertical[i] = newRowVertical;
            rowsAbsolute[i] = newRowAbsolute;
        }
        var index = totalLines;
        const base = {};
        base['id'] = "base";
        base['lineName'] = "Base";
        for (let n = 0; n < verticalTitlesTable.length; n++) {
            columnTitle = verticalTitlesTable[n];
            colFieldIndex = "col" + (n + 2);
            for (let i = 0; i < studies.length; i++) {
                if (studies[i].evolVagaId === columnTitle) {
                    var totalBase = studies[i].studyResults.totalSumWeithingFactorsFirstVariable;
                    result = parseFloat(totalBase).toFixed(0);
                    base[colFieldIndex] = Intl.NumberFormat('pt-PT', { useGrouping: true, }).format(result);
                    rowsVertical[index] = base;
                    rowsAbsolute[index] = base;
                }
            }
        }

        this.setState({ rowDataVertical: rowsVertical });
        this.setState({ rowDataAbsolute: rowsAbsolute });
    }

    getRowsMedia(totalLines, horizontalTitlesTable, verticalTitlesTable, studies) {
        var rowsMedia = [];
        for (let i = 0; i < totalLines; i++) {
            const lineTitle = horizontalTitlesTable[i];
            const newRowMedia = { "id": i + 1, "lineName": lineTitle };
            for (let n = 0; n < verticalTitlesTable.length; n++) {
                var columnTitle = verticalTitlesTable[n];
                var colFieldIndex = "col" + (n + 2);

                for (let j = 0; j < studies.length; j++) {
                    if (studies[j].evolVagaId === columnTitle) {
                        var mediaList = studies[j].studyResults.media;
                        var mediaArray = Object.keys(mediaList).map(key => mediaList[key]);
                        var lineTotalsArray = Object.keys(studies[j].studyResults.lineTotals).map(key => studies[j].studyResults.lineTotals[key]);
                        var lineTotalsLength = lineTotalsArray.length;
                        var media = 0;
                        var horizontalTitlesTableArray =
                            this.getColumnNames(studies[j].studyResults.aggregatedResults[0].aggregatedResults,
                                lineTotalsLength);
                        for (let k=0; k<horizontalTitlesTableArray.length; k++){
                            if (horizontalTitlesTableArray[k]===lineTitle){
                                media = mediaArray[k];
                                break;
                            }
                        }
                        
                        var result = parseFloat(media).toFixed(2);
                        newRowMedia[colFieldIndex] = Intl.NumberFormat('pt-PT', { useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2}).format(result);
                    }
                }
            }
            rowsMedia[i] = newRowMedia;
        }

        var index = totalLines;
        const base = {};
        base['id'] = "base";
        base['lineName'] = "Base";
        for (let n = 0; n < verticalTitlesTable.length; n++) {
            columnTitle = verticalTitlesTable[n];
            colFieldIndex = "col" + (n + 2);
            for (let i = 0; i < studies.length; i++) {
                if (studies[i].evolVagaId === columnTitle) {
                    var totalBase = studies[i].studyResults.totalSumWeithingFactorsFirstVariable;
                    result = parseFloat(totalBase).toFixed(0);
                    base[colFieldIndex] = Intl.NumberFormat('pt-PT', { useGrouping: true, }).format(result);
                    rowsMedia[index] = base;
                }
            }
        }
        this.setState({ rowDataMedia: rowsMedia });
    }

    getRowsNPS(totalLines, horizontalTitlesTable, verticalTitlesTable, studies) {
        var rowsNPS = [];
        for (let i = 0; i < totalLines; i++) {
            const lineTitle = horizontalTitlesTable[i];
            const newRowNPS = { "id": i + 1, "lineName": lineTitle };
            for (let n = 0; n < verticalTitlesTable.length; n++) {
                var columnTitle = verticalTitlesTable[n];
                var colFieldIndex = "col" + (n + 2);

                for (let j = 0; j < studies.length; j++) {
                    if (studies[j].evolVagaId === columnTitle) {
                        var npsList = studies[j].studyResults.nps;
                        var npsArray = Object.keys(npsList).map(key => npsList[key]);

                        var lineTotalsArray = Object.keys(studies[j].studyResults.lineTotals).map(key => studies[j].studyResults.lineTotals[key]);
                        var lineTotalsLength = lineTotalsArray.length;
                        var nps = 0;
                        var horizontalTitlesTableArray =
                            this.getColumnNames(studies[j].studyResults.aggregatedResults[0].aggregatedResults,
                                lineTotalsLength);
                        for (let k=0; k<horizontalTitlesTableArray.length; k++){
                            if (horizontalTitlesTableArray[k]===lineTitle){
                                nps = npsArray[k];
                                break;
                            }
                        }

                        var result = parseFloat(nps).toFixed(0);
                        if (result==='-0'){
                            result=0;
                        }
                        newRowNPS[colFieldIndex] = Intl.NumberFormat('pt-PT', { useGrouping: true, }).format(result);
                    }
                }
            }
            rowsNPS[i] = newRowNPS;
        }
        var index = totalLines;
        const base = {};
        base['id'] = "base";
        base['lineName'] = "Base";
        for (let n = 0; n < verticalTitlesTable.length; n++) {
            columnTitle = verticalTitlesTable[n];
            colFieldIndex = "col" + (n + 2);
            for (let i = 0; i < studies.length; i++) {
                if (studies[i].evolVagaId === columnTitle) {
                    var totalBase = studies[i].studyResults.totalSumWeithingFactorsFirstVariable;
                    result = parseFloat(totalBase).toFixed(0);
                    base[colFieldIndex] = Intl.NumberFormat('pt-PT', { useGrouping: true, }).format(result);
                    rowsNPS[index] = base;
                }
            }
        }
        this.setState({ rowDataNPS: rowsNPS });
    }

    initializeState(studies) {
        this.setState({ gotData: false });
        var verticalTitlesTableArray = this.getVerticalTitleTable(studies);
        this.setState({ verticalTitlesTable: verticalTitlesTableArray });
        //var totalColumns = verticalTitlesTableArray.length;

        var horizontalTitlesTableArray = this.getHorizontalTitleTable(studies);
        var totalLines = horizontalTitlesTableArray.length;
        this.setState({ horizontalTitlesTable: horizontalTitlesTableArray });
        if (this.state.hasMedia) {
            this.getRowsMedia(totalLines, horizontalTitlesTableArray, verticalTitlesTableArray, studies);
            this.buildChartDataMedia(totalLines,horizontalTitlesTableArray, studies);
            if (this.state.hasNPS) {
                this.getRowsNPS(totalLines, horizontalTitlesTableArray, verticalTitlesTableArray, studies);
                this.buildChartDataNPS(totalLines,horizontalTitlesTableArray, studies);
            }
            if (this.state.rowDataMedia.length > 0) {
                this.setState({ gotData: true });
            }
        } else {
            this.getRows(totalLines, horizontalTitlesTableArray, verticalTitlesTableArray, studies);
            this.buildLineChartData(totalLines, horizontalTitlesTableArray, studies);

            if (this.state.rowDataVertical.length > 0 || this.state.rowDataAbsolute.length > 0) {
                this.setState({ gotData: true });
            }
        }
    }

    componentDidUpdate = async (prevProps) => {

        if (this.props.studyName !== prevProps.studyName ||
            this.props.waveID !== prevProps.waveID ||
            this.props.waveName !== prevProps.waveName ||
            this.props.lineName !== prevProps.lineName ||
            this.props.selectedVagasEvolutivo !== prevProps.selectedVagasEvolutivo ||
            this.props.filters !== prevProps.filters) {
            this.setState({ gotData: false });
            
            const result = await GetDataEvolutivo(
                this.props.username,
                this.props.token,
                this.props.studyName,
                this.props.waveName,
                this.props.lineName,
                this.props.selectedVagasEvolutivo,
                this.props.filters
            );

            const aux = result.replaceAll("NaN", "\"\"");
            const studies = JSON.parse(aux);
            const filtersArray = ProcessarFiltros(this.props.filters);
            this.setState({ filtersArray: filtersArray });

            this.initializeState(studies);
        }

    }

    componentDidMount = async () => {
        const result = await GetDataEvolutivo(
            this.props.username,
            this.props.token,
            this.props.studyName,
            this.props.waveName,
            this.props.lineName,
            this.props.selectedVagasEvolutivo,
            this.props.filters
        );
        const aux = result.replaceAll("NaN", "\"\"");
        const studies = JSON.parse(aux);

        const filtersArray = ProcessarFiltros(this.props.filters);
        this.setState({ filtersArray: filtersArray });

        this.initializeState(studies);
    }

    buildChartDataMedia(totalLines,horizontalTitlesTable, studies){
        var chartRows = [];
        var index = 0;
        for (let n = 0; n < studies.length; n++) {
            var chartRow = { vaga: studies[n].evolVagaId };
            for (let i = 0; i < totalLines; i++) {
                const lineTitle = horizontalTitlesTable[i];
                var mediaList = studies[n].studyResults.media;
                var mediaArray = Object.keys(mediaList).map(key => mediaList[key]);
                var media = 0;
                var horizontalTitlesTableArray =
                this.getColumnNames(studies[n].studyResults.aggregatedResults[0].aggregatedResults,
                    totalLines);
                for (let k=0; k<horizontalTitlesTableArray.length; k++){
                    if (horizontalTitlesTableArray[k]===lineTitle){
                        media = mediaArray[k];
                        break;
                    }
                }
                
                var result = parseFloat(media).toFixed(1);
                chartRow = { ...chartRow, [lineTitle]: result };
            }
            chartRows[index] = chartRow;
            index++;
        }
        this.setState({ lineChartDataMedia: chartRows });
    }
    buildChartDataNPS(totalLines,horizontalTitlesTable, studies){
        var chartRows = [];
        var index = 0;
        for (let n = 0; n < studies.length; n++) {
            var chartRow = { vaga: studies[n].evolVagaId };
            for (let i = 0; i < totalLines; i++) {
                const lineTitle = horizontalTitlesTable[i];
                var npsList = studies[n].studyResults.nps;
                var npsArray = Object.keys(npsList).map(key => npsList[key]);
                var nps = 0;
                var horizontalTitlesTableArray =
                this.getColumnNames(studies[n].studyResults.aggregatedResults[0].aggregatedResults,
                    totalLines);
                for (let k=0; k<horizontalTitlesTableArray.length; k++){
                    if (horizontalTitlesTableArray[k]===lineTitle){
                        nps = npsArray[k];
                        break;
                    }
                }
                var result = parseFloat(nps).toFixed(0);
                chartRow = { ...chartRow, [lineTitle]: result };
            }
            chartRows[index] = chartRow;
            index++;
        }
        this.setState({ lineChartDataNPS: chartRows });
    }
    buildLineChartData(totalLines, horizontalTitlesTable, studies) {
        var chartRows = [];
        var index = 0;
        for (let n = 0; n < studies.length; n++) {
            var chartRow = { vaga: studies[n].evolVagaId };
            var lineVerticalPercentages = Object.keys(studies[n].studyResults.lineVerticalPercentages).map(key => studies[n].studyResults.lineVerticalPercentages[key]);
            var lineTotalsWithNames = [];

            if (typeof studies[n].studyResults.lineTotalsWithLineNames !== 'undefined') {
                lineTotalsWithNames = Object.keys(studies[n].studyResults.lineTotalsWithLineNames).map(key => studies[n].studyResults.lineTotalsWithLineNames[key]);
            } else {
                var aggResults = studies[n].studyResults.aggregatedResults[0].aggregatedResults;
                for (let i = 0; i < aggResults.length; i++) {
                    lineTotalsWithNames[i] = aggResults[i].nameLine;
                }
            }
            for (let i = 0; i < totalLines; i++) {

                const lineTitle = horizontalTitlesTable[i];

                for (let k = 0; k < lineTotalsWithNames.length; k++) {
                    if (lineTotalsWithNames[k] === lineTitle) {
                        if (typeof lineVerticalPercentages[k] !== 'undefined') {
                            var vp = lineVerticalPercentages[k];
                            var vpFloat = parseFloat(vp).toFixed(1);
                            chartRow = { ...chartRow, [lineTitle]: vpFloat };
                        }
                        break;
                    }
                }
            }
            chartRows[index] = chartRow;
            index++;
        }

        this.setState({ lineChartData: chartRows });
    }



    handleTabClick = (tabName) => {
        this.setState(prevState => ({
            activeTab: tabName,
        }))
    };

    render() {
        return (
            <div style={{ backgroundColor: '#fff', color: '#000', padding: 20, width: "100%", minHeight: 649 }}>
                {!this.state.gotData &&

                    <SpinnerContent/>

                }
                {this.state.gotData && this.state.horizontalTitlesTable.length === 0 &&
                    <TablesNoData
                        title1=""
                        title2=""
                        title3="&#x2717;&nbsp;Os critérios selecionados não retornam quaisquer resultados." />
                }
                {!this.state.hasMedia && !this.state.hasNPS && this.state.gotData && this.state.horizontalTitlesTable.length > 0 &&
                    <div>
                        <div className="tabs-container"
                            style={{ width: "900px", marginLeft: "auto", marginRight: "auto", textAlign: "left" }}>
                            <h4>{this.state.firstAssociatedVariable}</h4>
                            <hr />
                            <table width="150px">
                                <tr>
                                    <td>
                                        <div className={this.state.activeTab === 'tab1' ? 'tab active' : 'tab'} onClick={() => this.handleTabClick('tab1')}>
                                            Percentagem vertical
                                        </div>
                                    </td>
                                    <td>
                                        <div className={this.state.activeTab === 'tab2' ? 'tab active' : 'tab'} onClick={() => this.handleTabClick('tab2')}>
                                            Valores absolutos
                                        </div>
                                    </td>
                                </tr>
                            </table>
                            <div className="tab-content">

                                {this.state.activeTab === 'tab1' && this.state.verticalTitlesTable.length > 0 &&
                                    <div>
                                        <DatatableTables
                                            evolutivo={true}
                                            studyName={this.state.waveName}
                                            lineName={this.state.firstAssociatedVariable}
                                            topColumn="Vaga"
                                            columnTitles={this.state.verticalTitlesTable}
                                            dataTypeName="Percentagens verticais (%)"
                                            rowData={this.state.rowDataVertical}
                                            textoBase={this.state.textoBase}
                                            textoNota={this.state.textoNota}
                                            filtrosArray={this.state.filtersArray}
                                        />


                                    </div>
                                }
                                {this.state.activeTab === 'tab2' && this.state.rowDataAbsolute.length > 0 &&
                                    <div>
                                        <DatatableTables
                                            evolutivo={true}
                                            studyName={this.state.waveName}
                                            lineName={this.state.firstAssociatedVariable}
                                            topColumn="Vaga"
                                            columnTitles={this.state.verticalTitlesTable}
                                            dataTypeName="Valores absolutos"
                                            rowData={this.state.rowDataAbsolute}
                                            textoBase={this.state.textoBase}
                                            textoNota={this.state.textoNota}
                                            filtrosArray={this.state.filtersArray}
                                        />

                                    </div>
                                }

                            </div>
                        </div>
                        <div>
                            <LinesChart
                                chartData={this.state.lineChartData}
                                linesNames={this.state.horizontalTitlesTable}
                                chartTitle={this.state.firstAssociatedVariable}
                                dataKey="vaga" />
                        </div>
                    </div>
                }
                {(this.state.hasMedia || this.state.hasNPS) && this.state.gotData && this.state.horizontalTitlesTable.length > 0 &&
                    <div>
                        <div className="tabs-container"
                            style={{ width: "900px", marginLeft: "auto", marginRight: "auto", textAlign: "left" }}>
                            <h3>{this.state.firstAssociatedVariable}</h3>
                            <hr />
                            <table width="150px">
                                <tr>
                                    <td>
                                        <div className={this.state.activeTab === 'tab1' ? 'tab active' : 'tab'} onClick={() => this.handleTabClick('tab1')}>
                                            Média
                                        </div>
                                    </td>
                                    {this.state.hasNPS &&
                                        <td>
                                            <div className={this.state.activeTab === 'tab2' ? 'tab active' : 'tab'} onClick={() => this.handleTabClick('tab2')}>
                                                NPS
                                            </div>
                                        </td>
                                    }
                                </tr>
                            </table>
                            <div className="tab-content">

                                {this.state.activeTab === 'tab1' && this.state.rowDataMedia.length > 0 &&
                                    <div>
                                        <DatatableTables
                                            evolutivo={true}
                                            studyName={this.state.waveName}
                                            lineName={this.state.firstAssociatedVariable}
                                            topColumn="Vaga"
                                            columnTitles={this.state.verticalTitlesTable}
                                            dataTypeName="Média"
                                            rowData={this.state.rowDataMedia}
                                            textoBase={this.state.textoBase}
                                            textoNota={this.state.textoNota}
                                            filtrosArray={this.state.filtersArray}
                                        />


                                    </div>
                                }
                                {this.state.activeTab === 'tab2' && this.state.rowDataNPS.length > 0 &&
                                    <div>
                                        <DatatableTables
                                            evolutivo={true}
                                            studyName={this.state.waveName}
                                            lineName={this.state.firstAssociatedVariable}
                                            topColumn="Vaga"
                                            columnTitles={this.state.verticalTitlesTable}
                                            dataTypeName="Net Promoter Score"
                                            rowData={this.state.rowDataNPS}
                                            textoBase={this.state.textoBase}
                                            textoNota={this.state.textoNota}
                                            filtrosArray={this.state.filtersArray}
                                        />

                                    </div>
                                }
                                {this.state.activeTab === 'tab1' && this.state.rowDataMedia.length > 0 &&
                                    <div>
                                        <LinesChart
                                            chartData={this.state.lineChartDataMedia}
                                            linesNames={this.state.horizontalTitlesTable}
                                            chartTitle={this.state.firstAssociatedVariable}
                                            dataKey="vaga" />
                                    </div>
                                }
                                {this.state.activeTab === 'tab2' && this.state.rowDataNPS.length > 0 &&
                                    <div>
                                    <LinesChart
                                        chartData={this.state.lineChartDataNPS}
                                        linesNames={this.state.horizontalTitlesTable}
                                        chartTitle={this.state.firstAssociatedVariable}
                                        dataKey="vaga" />
                                </div>
                                }
                            </div>
                        </div>

                    </div>
                }
            </div>
        );
    }
}

export default EvolutiveStudytData;
