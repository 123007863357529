import React from 'react';
import './Tabs.css';
import BarChartwRefLine from '../Charts/BarChartwRefLine';
import DatatableTables from '../Datatables/DatatableTables';
import BartChartTables from '../Charts/BarChartTables';
import GetDataVaga from './GetDataVaga';
import ProcessarFiltros from './ProcessarFiltros';
import TablesNoData from './TablesNoData';
import SpinnerContent from '../common/components/SpinnerContent';

class SimpleStudytData extends React.Component {
    constructor(props) {
        super(props);
        const studyName = props.studyName;
        const waveName = props.waveName;
        const lineName = props.lineName;
        const columnName = props.columnName;
        const columnLabel = props.columnLabel;
        const filters = props.filters;

        this.state = {
            studyName: studyName,
            waveName: waveName,
            lineName: lineName,
            columnName: columnName,
            columnLabel: columnLabel,
            filters: filters,
            activeTab: 'tab1',
            study: null,
            filtersArray: [],
            verticalTitlesTable: [],
            horizontalTitlesTable: [],
            columnDefs: [],
            rowDataVertical: [],
            rowDataHorizontal: [],
            rowDataAbsolute: [],
            horizontalBarChartData: [],
            npsChartData: [],
            mediaChartData: [],
            aggVerticalPercentages: [],
            aggHorizontalPercentages: [],
            aggResultsAbs: [],
            lineTotals: [],
            totalLines: 0,
            baseTotals: [],
            totalBase: 0,
            mediaTotals: [],
            totalMedia: 0,
            npsTotals: [],
            totalNps: 0,
            pinnedBottomRowDataVertical: [],
            firstAssociatedVariable: "",
            secondAssociatedVariable: "",
            textoBase: "",
            textoNota: "",
            hasMedia: false,
            hasNPS: false,
            displayBarChart: false,
            displayMediaChart: false,
            displayNpsChart: false,
            totalColumns: 0,
            maxVP: 100,
            gotData: false,
        }

        this.getBaseRow = this.getBaseRow.bind(this);
        this.getMediaRow = this.getMediaRow.bind(this);
        this.getNpsRow = this.getNpsRow.bind(this);
        this.getVerticalTitleTable = this.getVerticalTitleTable.bind(this);
        this.getHorizontalTitleTable = this.getHorizontalTitleTable.bind(this);
        this.getRows = this.getRows.bind(this);
        this.initializeState = this.initializeState.bind(this);
        this.buildBarChartData = this.buildBarChartData.bind(this);
        this.buildCharwRefLinetData = this.buildCharwRefLinetData.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.componentDidUpdate = this.componentDidUpdate.bind(this);
        this.processarTextoBase = this.processarTextoBase.bind(this);
        this.processarTextoNota = this.processarTextoNota.bind(this);
    }

    getVerticalTitleTable(aggResults) {
        var verticalTitlesTableArray = [];
        var n = 0;
        for (let i = 0; i < aggResults.aggregatedResults.length; i++) {
            var element = aggResults.aggregatedResults[i].nameColumn;
            if (!verticalTitlesTableArray.includes(element)) {
                verticalTitlesTableArray[n] = element;
                n++;
            }
        }
        return verticalTitlesTableArray;
    }

    getHorizontalTitleTable(aggResults) {
        var horizontalTitlesTableArray = [];
        var n = 0;
        for (let i = 0; i < aggResults.aggregatedResults.length; i++) {
            var element = aggResults.aggregatedResults[i].nameLine;
            if (!horizontalTitlesTableArray.includes(element)) {
                horizontalTitlesTableArray[n] = element;
                n++;
            }
        }
        return horizontalTitlesTableArray;
    }

    getRows(totalLines, horizontalTitlesTable, verticalTitlesTable, totalValues, values, totalBase, baseTotals, totalMedia, mediaTotals, totalNps, npsTotals, decimalPlaces) {
        var rows = [];
        for (let i = 0; i < totalLines; i++) {
            const lineTitle = horizontalTitlesTable[i];

            const newRow = { "id": i + 1, "lineName": lineTitle };
            for (let n = 0; n < verticalTitlesTable.length; n++) {
                var resultTotal = parseFloat(totalValues[i]).toFixed(decimalPlaces);
                newRow["totalCol"] = Intl.NumberFormat('pt-PT', { useGrouping: true, minimumFractionDigits: decimalPlaces}).format(resultTotal);

                var colFieldIndex = "col" + (n + 2);

                var columnTitle = verticalTitlesTable[n];
                var resultValue = '';

                for (let x = 0; x < values.length; x++) {
                    var lineIndex = values[x].nameLine;
                    var columnIndex = values[x].nameColumn;
                    if (columnIndex === columnTitle && lineIndex === lineTitle) {
                        resultValue = values[x].resultValue;
                        var result = parseFloat(resultValue).toFixed(decimalPlaces)
                        newRow[colFieldIndex] = Intl.NumberFormat('pt-PT', { useGrouping: true, minimumFractionDigits: decimalPlaces}).format(result);
                        break;
                    }
                }
            }
            rows[i] = newRow;
        }
        var index = totalLines;

        const baseRow = this.getBaseRow(totalBase, baseTotals, verticalTitlesTable);
        rows[index] = baseRow;
        index++;

        if (this.state.hasMedia) {
            const mediaRow = this.getMediaRow(totalMedia, mediaTotals, verticalTitlesTable);
            rows[index] = mediaRow;
            index++;
        }

        if (this.state.hasNPS) {
            const npsRow = this.getNpsRow(totalNps, npsTotals, verticalTitlesTable);
            rows[index] = npsRow;
        }
        return rows;
    }

    processarTextoNota(study) {
        var textoNota = "";
        if (typeof study.textoNota !== 'undefined') {
            textoNota = study.textoNota;
        }
        return textoNota;
    }

    processarTextoBase(study) {
        var textoBase = "";
        if (typeof study.textoBase !== 'undefined') {
            textoBase = study.textoBase;
            var baseAmostral = 30;
            var univVal = study.univVal;
            if (typeof univVal !== 'undefined' && univVal !== null && univVal !== 0) {
                var totalSum = study.totalSumWeithingFactorsFirstVariable;
                if (totalSum / univVal !== 1) {
                    var calcVal = totalSum / univVal * 100;
                    var answers = study.answersNumber;
                    if (answers < baseAmostral) {
                        textoBase += " <br>Observações:<font color=\"red\"> Base amostral reduzida</font>."
                    } else {
                        textoBase += " (<strong>" + Intl.NumberFormat('pt-PT', { useGrouping: true, minimumFractionDigits: 1}).format(parseFloat(calcVal).toFixed(1)) + "% </strong> do Universo, <strong>" + Intl.NumberFormat('pt-PT', { useGrouping: true, }).format(univVal) + "</strong>).";
                    }
                }
            }
        }
        return textoBase;
    }

    initializeState(study) {
        const totalBase = study.totalSumWeithingFactorsFirstVariable;
        this.setState({ totalBase: totalBase });
        if (totalBase !== 0) {
            var totalMediaValue = 0;            
            var mediaTotalsArray = [];
            if (typeof study.media !== 'undefined') {
                totalMediaValue = Intl.NumberFormat('pt-PT', { useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2}).format(parseFloat(study.totalMedia).toFixed(2));
                mediaTotalsArray = Object.keys(study.media).map(key => study.media[key])
                this.setState({ hasMedia: true });
                this.setState({ totalMedia: totalMediaValue });
            }
            var npsTotalsArray = [];
            var totalNpsValue = 0;
            if (typeof study.nps !== 'undefined') {
                npsTotalsArray = Object.keys(study.nps).map(key => study.nps[key])
                this.setState({ hasNPS: true });
                var npsAuxValue=parseFloat(study.totalNps).toFixed(0);
                if (npsAuxValue==='-0'){
                    npsAuxValue=0;
                }
                totalNpsValue = Intl.NumberFormat('pt-PT', { useGrouping: true, minimumFractionDigits: 0}).format(npsAuxValue);
                this.setState({ totalNps: totalNpsValue });                
            }

            const baseTotals = Object.keys(study.baseTotals).map(key => study.baseTotals[key]);
            this.setState({ baseTotals: baseTotals });

            const aggResults = study.aggregatedResults[0];
            const verticalTitlesTableArray = this.getVerticalTitleTable(aggResults);
            this.setState({ verticalTitlesTable: verticalTitlesTableArray });
            const totalColumns = verticalTitlesTableArray.length;
            this.setState({ totalColumns: totalColumns });

            const horizontalTitlesTableArray = this.getHorizontalTitleTable(aggResults);
            const totalLines = horizontalTitlesTableArray.length;
            this.setState({ totalLines: totalLines });
            this.setState({ horizontalTitlesTable: horizontalTitlesTableArray });

            const aggVerticalPercentages = study.aggregatedResults[0].aggregatedVerticalPercentage;
            this.setState({ aggVerticalPercentages: aggVerticalPercentages });
            const lineVerticalPercentages = Object.keys(study.lineVerticalPercentages).map(key => study.lineVerticalPercentages[key]);
            this.setState({ lineVerticalPercentages: lineVerticalPercentages });
            const verticalRows = this.getRows(totalLines, horizontalTitlesTableArray,
                verticalTitlesTableArray, lineVerticalPercentages, aggVerticalPercentages, totalBase, baseTotals, totalMediaValue, mediaTotalsArray,
                totalNpsValue, npsTotalsArray, 1);

            const aggHorizontalPercentages = study.aggregatedResults[0].aggregatedHorizontalPercentage;
            this.setState({ aggHorizontalPercentages: aggHorizontalPercentages });
            const lineHorizontalPercentages = Object.keys(study.lineHorizontalPercentages).map(key => study.lineHorizontalPercentages[key]);
            const horizontalRows = this.getRows(totalLines, horizontalTitlesTableArray,
                verticalTitlesTableArray, lineHorizontalPercentages, aggHorizontalPercentages, totalBase, baseTotals, totalMediaValue, mediaTotalsArray, totalNpsValue, npsTotalsArray, 1);

            const aggResultsAbs = study.aggregatedResults[0].aggregatedResults;
            this.setState({ aggResultsAbs: aggResultsAbs });
            const lineTotals = Object.keys(study.lineTotals).map(key => study.lineTotals[key]);
            const absoluteRows = this.getRows(totalLines, horizontalTitlesTableArray,
                verticalTitlesTableArray, lineTotals, aggResultsAbs, totalBase, baseTotals, totalMediaValue, mediaTotalsArray, totalNpsValue, npsTotalsArray, 0);
            this.setState({ rowDataHorizontal: horizontalRows, rowDataVertical: verticalRows, rowDataAbsolute: absoluteRows });

            if (!this.state.hasNPS && !this.state.hasMedia) {
                this.buildBarChartData(totalLines, horizontalTitlesTableArray, lineVerticalPercentages);
                this.setState({ displayBarChart: true });
            } else if (this.state.hasNPS) {
                this.setState({ displayNpsChart: true });
                var npsChartData = this.buildCharwRefLinetData(totalColumns, verticalTitlesTableArray, npsTotalsArray, "NPS");
                this.setState({ npsChartData: npsChartData });
            } else {
                this.setState({ displayMediaChart: true });
                var mediaChartData = this.buildCharwRefLinetData(totalColumns, verticalTitlesTableArray, mediaTotalsArray, "Média");
                this.setState({ mediaChartData: mediaChartData });
            }
        }
    }


    componentDidUpdate = async (prevProps) => {
        if (
            prevProps.studyName !== this.props.studyName ||
            prevProps.waveName !== this.props.waveName ||
            prevProps.lineName !== this.props.lineName ||
            prevProps.columnName !== this.props.columnName ||
            prevProps.filters !== this.props.filters) {
            this.setState({ gotData: false });
            const result = await GetDataVaga(
                this.props.username,
                this.props.token,
                this.props.studyName,
                this.props.waveName,
                this.props.lineName,
                this.props.columnName,
                this.props.filters);
            const aux = result.replaceAll("NaN", "\"\"");
            const study = JSON.parse(aux);
            this.setState({ studyName: this.props.studyName });
            this.setState({ waveName: this.props.waveName });
            const filtersArray = ProcessarFiltros(this.props.filters);
            this.setState({ filtersArray: filtersArray });


            const firstAssociatedVariable = study.firstAssociatedVariable;
            this.setState({ firstAssociatedVariable: firstAssociatedVariable });

            const secondAssociatedVariable = study.secondAssociatedVariable;
            if (secondAssociatedVariable===firstAssociatedVariable){
                this.setState({secondAssociatedVariable: this.props.columnLabel});
            } else {
                this.setState({ secondAssociatedVariable: secondAssociatedVariable });
            }
            const textoBase = this.processarTextoBase(study);
            this.setState({ textoBase: textoBase });
            const textoNota = this.processarTextoNota(study);
            this.setState({ textoNota: textoNota });

            this.initializeState(study);

            this.setState({ gotData: true });

        }
    }

    componentDidMount = async () => {
        const result = await GetDataVaga(
            this.props.username,
            this.props.token,
            this.props.studyName,
            this.props.waveName,
            this.props.lineName,
            this.props.columnName,
            this.props.filters);
        const aux = result.replaceAll("NaN", "\"\"");
        const study = JSON.parse(aux);

        this.setState({ studyName: this.props.studyName });
        this.setState({ waveName: this.props.waveName });
        const filtersArray = ProcessarFiltros(this.props.filters);
        this.setState({ filtersArray: filtersArray });


        const firstAssociatedVariable = study.firstAssociatedVariable;
        this.setState({ firstAssociatedVariable: firstAssociatedVariable });
        const secondAssociatedVariable = study.secondAssociatedVariable;
        if (secondAssociatedVariable===firstAssociatedVariable){
            this.setState({secondAssociatedVariable: this.props.columnLabel});
        } else {
            this.setState({ secondAssociatedVariable: secondAssociatedVariable });
        }
        const textoBase = this.processarTextoBase(study);
        this.setState({ textoBase: textoBase });
        const textoNota = this.processarTextoNota(study);
        this.setState({ textoNota: textoNota });

        this.initializeState(study);

        this.setState({ gotData: true });
    }

    getBaseRow(totalBase, baseTotals, verticalTitlesTable) {
        const base = {};
        base['id'] = "base";
        base['lineName'] = "Base";
        totalBase = parseFloat(totalBase).toFixed(0);
        base['totalCol'] = Intl.NumberFormat('pt-PT', { useGrouping: true, }).format(totalBase);
        for (let i = 0; i < baseTotals.length; i++) {
            var index = "col" + (i + 2);
            var result = parseFloat(baseTotals[i]).toFixed(0);
            result = Intl.NumberFormat('pt-PT', { useGrouping: true, }).format(result);
            base[index] = result;
        }
        return base;
    }

    getMediaRow(totalMedia, mediaTotals, verticalTitlesTable) {
        const media = {};
        media['id'] = "media";
        media['lineName'] = "Média";
        var formatter = new Intl.NumberFormat('pt-PT', { 
            useGrouping: true, 
            minimumFractionDigits: 2, 
            maximumFractionDigits: 2 
        });
        
        media['totalCol'] = totalMedia;
        for (let i = 0; i < mediaTotals.length; i++) {
            var index = "col" + (i + 2);
            var mediaCell = parseFloat(mediaTotals[i]);
            var fixedMediaCell = Number(mediaCell.toFixed(2));
            
            media[index] = formatter.format(fixedMediaCell);
        }
        return media;
    }

    getNpsRow(totalNps, npsTotals, verticalTitlesTable) {
        const nps = {};
        nps['id'] = "nps";
        nps['lineName'] = "Net Promoter Score";
        //var totalNps = parseFloat(totalNps).toFixed(0);
        //nps['totalCol'] = Intl.NumberFormat('pt-PT', { useGrouping: true, }).format(totalNps);
        nps['totalCol'] = totalNps;
        for (let i = 0; i < npsTotals.length; i++) {
            var index = "col" + (i + 2);
            var result = parseFloat(npsTotals[i]).toFixed(0);
            if (result==='-0'){
                result=0;
            }
            nps[index] = Intl.NumberFormat('pt-PT', { useGrouping: true, }).format(result);
        }
        return nps;
    }

    buildBarChartData(totalLines, horizontalTitlesTable, lineVerticalPercentages) {
        var rows = [];
        var maxVP = 0;
        for (let i = 0; i < totalLines; i++) {
            var lineTitle = horizontalTitlesTable[i];
            var vp = lineVerticalPercentages[i];
            var vpFloat = parseFloat(vp).toFixed(1);
            if (vpFloat > parseFloat(maxVP)) {
                maxVP = vpFloat;
            }
            var newRow = { name: lineTitle };
            var y = "% vertical";
            newRow[`${y}`] = parseFloat(vpFloat);

            //newRow = { ...newRow, `{${y}}`: vpFloat };

            rows[i] = newRow;
        }
        this.setState({ horizontalBarChartData: rows });
        this.setState({ maxVP: maxVP });
    }

    buildCharwRefLinetData(totalColumns, verticalTitlesTable, totals, xKey) {
        var rows = [];
        for (let i = 0; i < totalColumns; i++) {
            var lineTitle = verticalTitlesTable[i];
            var value = totals[i];
            var valueFloat = 0;
            if (xKey === 'Média') {
                valueFloat = parseFloat(value).toFixed(2);
            } else {
                valueFloat = parseFloat(value).toFixed(0);
            }

            var newRow = { name: lineTitle };
            newRow = { ...newRow, [xKey]: parseFloat(valueFloat) };

            rows[i] = newRow;
        }
        return rows;

    }

    handleTabClick = (tabName) => {
        this.setState(prevState => ({
            activeTab: tabName,
        }))
    };

    render() {
        return (
            <div style={{ backgroundColor: '#fff', color: '#000', padding: 20, width: "100%", minHeight: 649 }}>
                {!this.state.gotData &&
                    <SpinnerContent/>
                }
                {this.state.gotData && this.state.totalBase === 0 &&

                    <TablesNoData
                        title1=""
                        title2=""
                        title3="&#x2717;&nbsp;Os critérios selecionados não retornam quaisquer resultados." />

                }
                {this.state.gotData && this.state.totalBase > 0 &&
                    <div className="tabs-container"
                        style={{ width: "900px", marginLeft: "auto", marginRight: "auto", textAlign: "left" }}>
                        <h4>{this.state.firstAssociatedVariable}</h4>
                        <hr />
                        <br />
                        <table width="20%">
                            <tr>
                                <td>
                                    <div className={this.state.activeTab === 'tab1' ? 'tab active' : 'tab'} onClick={() => this.handleTabClick('tab1')}>
                                        Percentagem vertical
                                    </div>
                                </td>
                                <td>
                                    <div className={this.state.activeTab === 'tab2' ? 'tab active' : 'tab'} onClick={() => this.handleTabClick('tab2')}>
                                        Percentagem horizontal
                                    </div>
                                </td>
                                <td>
                                    <div className={this.state.activeTab === 'tab3' ? 'tab active' : 'tab'} onClick={() => this.handleTabClick('tab3')}>
                                        Valores absolutos
                                    </div>
                                </td>
                            </tr>
                        </table>
                        <div className="tab-content">

                            {
                                this.state.activeTab === 'tab1' && this.state.rowDataVertical.length > 0 &&
                                <DatatableTables
                                    evolutivo={false}
                                    studyName={this.state.waveName}
                                    lineName={this.state.firstAssociatedVariable}
                                    topColumn={this.state.secondAssociatedVariable}
                                    columnTitles={this.state.verticalTitlesTable}
                                    dataTypeName="Percentagens verticais (%)"
                                    rowData={this.state.rowDataVertical}
                                    textoBase={this.state.textoBase}
                                    textoNota={this.state.textoNota}
                                    filtrosArray={this.state.filtersArray}
                                />
                            }
                            {this.state.activeTab === 'tab2' && this.state.rowDataHorizontal.length > 0 &&

                                <DatatableTables
                                    evolutivo={false}
                                    studyName={this.state.waveName}
                                    lineName={this.state.firstAssociatedVariable}
                                    topColumn={this.state.secondAssociatedVariable}
                                    columnTitles={this.state.verticalTitlesTable}
                                    dataTypeName="Percentagens horizontais (%)"
                                    rowData={this.state.rowDataHorizontal}
                                    textoBase={this.state.textoBase}
                                    textoNota={this.state.textoNota}
                                    filtrosArray={this.state.filtersArray}
                                />
                            }
                            {this.state.activeTab === 'tab3' && this.state.rowDataAbsolute.length > 0 &&

                                <DatatableTables
                                    evolutivo={false}
                                    studyName={this.state.waveName}
                                    topColumn={this.state.secondAssociatedVariable}
                                    lineName={this.state.firstAssociatedVariable}
                                    columnTitles={this.state.verticalTitlesTable}
                                    dataTypeName="Valores absolutos"
                                    rowData={this.state.rowDataAbsolute}
                                    textoBase={this.state.textoBase}
                                    textoNota={this.state.textoNota}
                                    filtrosArray={this.state.filtersArray}
                                />
                            }
                        </div>
                        {this.state.displayBarChart &&
                            <div>
                                <BartChartTables chartData={this.state.horizontalBarChartData}
                                    chartTitle={this.state.firstAssociatedVariable}
                                    columnName={this.state.secondAssociatedVariable}
                                    barDataKey="% vertical"
                                    aggHorizontalPercentages={this.state.aggHorizontalPercentages}
                                    totalColumns={this.state.totalColumns} />
                            </div>
                        }
                        {this.state.displayMediaChart &&
                            <div>
                                <BarChartwRefLine barDataKey="Média" chartData={this.state.mediaChartData} total={this.state.totalMedia} />
                            </div>

                        }
                        {this.state.displayNpsChart &&
                            <div>
                                <BarChartwRefLine barDataKey="NPS" chartData={this.state.npsChartData} total={this.state.totalNps} />
                            </div>

                        }

                    </div>
                }

            </div>
        );
    }
}

export default SimpleStudytData;
