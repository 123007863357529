import React, { useContext, useEffect, useRef, useState } from 'react';
import Select from 'react-select';
import './Navigation.css';
import { trackPromise } from 'react-promise-tracker';
import AuthContext from "../context/AuthProvider";
import axiosInstance from '../api/axios';
import makeAnimated from 'react-select/animated';
import { BsFilterCircleFill, BsFilter, BsFillTrashFill, BsFileEarmarkBarGraphFill } from "react-icons/bs";
import TablesNoData from './TablesNoData';
import { SOFTDATAWSURL } from '../config.js'
import SimpleStudytData from './SimpleStudyData';
import EvolutiveStudytData from './EvolutiveStudyData';
import setupAxiosInterceptors from '../api/interceptors';

const colourStyles = {
    control: base => ({
        ...base,
        fontSize: '14px'
    }),
    container: base => ({
        ...base,
        flex: 1
    }),
    menu: base => ({
        ...base,
        fontSize: '14px'
    }),
    placeholder: (styles) => {
        return {
            ...styles,
            fontSize: '14px',
        }
    },
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
            ...styles,
            fontSize: '14px',
            backgroundColor: isFocused ? "#CACACA" : null,
            color: "#333333",
            width: "auto"
        };
    }
};


const Navigation = () => {
    const authContext = useContext(AuthContext);
    const { auth } = useContext(AuthContext);
    const username = auth.user;
    const token = auth.token;
    const [studiesOptions, setStudiesOptions] = useState([]);
    const studiesDefaultOption = { label: "Selecione o estudo", value: "" }
    const [selectedStudy, setSelectedStudy] = useState(studiesDefaultOption);
    const [studyName, setStudyName] = useState(null);
    const [waves, setWaves] = useState([]);
    const [wavesOptions, setWavesOptions] = useState([]);
    const wavesDefaultOption = { label: "Selecione a vaga", value: "" }
    const [selectedWave, setSelectedWave] = useState(wavesDefaultOption);
    const [waveName, setWaveName] = useState(null);
    const [studyTypes, setStudyTypes] = useState([]);
    const [studyTypesOptions, setStudyTypesOptions] = useState([]);
    const studyTypesDefaultOption = { label: "Selecione a tipo", value: "" }
    const [selectedStudyType, setSelectedStudyType] = useState(studyTypesDefaultOption);
    const [isEvolutivo, setIsEvolutivo] = useState(null);
    const [themes, setThemes] = useState([]);
    const [themeName, setThemeName] = useState(null);
    const [themesOptions, setThemesOptions] = useState([]);
    const themesDefaultOption = { label: "Selecione o tema", value: "" }
    const [selectedTheme, setSelectedTheme] = useState(themesDefaultOption);
    const [hasThemes, setHasThemes] = useState(false);
    const [lines, setLines] = useState([]);
    const [lineName, setLineName] = useState(null);
    const [linesOptions, setLinesOptions] = useState([]);
    const linesDefaultOption = { label: "Selecione a variável em linha", value: "" }
    const [selectedLine, setSelectedLine] = useState(linesDefaultOption);
    const [columns, setColumns] = useState([]);
    const [columnName, setColumnName] = useState(null);
    const [columnLabel, setColumnLabel] = useState(null);
    const [columnsOptions, setColumnsOptions] = useState([]);
    const columnsDefaultOption = { label: "Selecione a variável em coluna", value: "" }
    const [selectedColumn, setSelectedColumn] = useState(columnsDefaultOption);
    const [vagasEvolutivos, setVagasEvolutivos] = useState([]);
    const [vagasEvolutivosOptions, setVagasEvolutivosOptions] = useState([]);
    const [selectedVagasEvolutivo, setSelectedVagasEvolutivo] = useState([]);
    const [totalVagasEvolutivo, setTotalVagasEvolutivo] = useState(0);
    const maxVagasEvolutivos = 7;
    const animatedComponents = makeAnimated();
    const [showFilter, setShowFilter] = useState(false);
    const [aggregators, setAggregators] = useState([]);
    const [aggregatorID, setAggregatorID] = useState(null);
    const [aggregatorName, setAggregatorName] = useState(null);
    const [aggregatorsOptions, setAggregatorsOptions] = useState([]);
    const aggregatorsDefaultOption = { label: "Selecione a variável do filtro", value: "" }
    const [selectedAggregator, setSelectedAggregator] = useState(aggregatorsDefaultOption);
    const [aggregatorLines, setAggregatorLines] = useState([]);
    const [aggregatorLineOptions, setAggregatorsLineOptions] = useState([]);
    const [selectedAggregatorLines, setSelectedAggregatorLines] = useState([]);
    const [showButtons, setShowButtons] = useState(false);
    const [data, setData] = useState(null);
    const [filters, setFilters] = useState([]);
    const [studiesLoaded, setStudiesLoaded] = useState(false);
    const [hasData, setHasData] = useState(false);
    const [showSimpleStudy, setShowSimpleStudy] = useState(false);
    const [showEvolutiveStudy, setShowEvolutiveStudy] = useState(false);
    const [enableVaga, setEnableVaga] = useState(false);
    const [enableTipoEstudo, setEnableTipoEstudo] = useState(false);
    const [enableTema, setEnableTema] = useState(false);
    const [enableLinha, setEnableLinha] = useState(false);
    const [enableColuna, setEnableColuna] = useState(false);
    const [enableVagasEvolutivos, setEnableVagasEvolutivos] = useState(false);
    const selectVagasEvolutivosRef = useRef();

    useEffect(() => {
        trackPromise(getStudies());
        setStudiesLoaded(true);


    }, []);

    const getStudies = async () => {
        const response = await axiosInstance.post(SOFTDATAWSURL + "/tables/getstudygroups",
            {},
            {
                params: {
                    username,
                    token
                }
            }
        );
        try {

            const result = await response.data;
            var i = 0;
            await result.forEach(element => {
                studiesOptions[i] = { value: element, label: element };
                i++;
            });


        } catch (err) {
            if (!err?.response) {
                console.log('Falha em aceder ao servidor');
            }
        }
    }


    const getWaves = async (studyName) => {
        const response = await axiosInstance.post(SOFTDATAWSURL + "/tables/getstudywaves",
            {},
            {
                params: {
                    username,
                    token,
                    studyName
                }
            }
        );
        try {

            const result = await response.data;
            var i = 0;
            setWaves([]);
            Object.keys(result).forEach(key => {
                waves[i] = { value: key, label: result[key] };
                i++;
            });
            setEnableVaga(true);

        } catch (err) {
            if (!err?.response) {
                console.log('Falha em aceder ao servidor');
            }
        }
    }

    const handleStudyChange = (selectedStudy) => {
        setEnableVaga(false);
        setSelectedStudy(selectedStudy);
        setStudyName(selectedStudy.value);
        limparVagasEstudo();
        trackPromise(getWaves(selectedStudy.value));
        setWavesOptions(waves);
    };

    const getStudyTypes = async (waveName) => {

        const response = await axiosInstance.post(SOFTDATAWSURL + "/tables/isevolutive",
            {},
            {
                params: {
                    username,
                    token,
                    studyName,
                    waveName
                }
            });

        try {

            const result = await response.data;
            setStudyTypes([]);
            studyTypes[0] = { value: "simples", label: "Vaga" };
            if (result) {
                studyTypes[1] = { value: "evolutivos", label: "Evolutivo" }
            }
            setEnableTipoEstudo(true);


        } catch (err) {
            if (!err?.response) {
                console.log('Falha em aceder ao servidor');
            }
        }
    }

    const getThemesOptions = async (isEvolutive) => {

        const response = await axiosInstance.post(SOFTDATAWSURL + "/tables/getthemeoptions",
            {},
            {
                params: {
                    username,
                    token,
                    studyName,
                    waveName,
                    isEvolutive
                }
            });

        try {

            const result = await response.data;
            setThemes([]);
            var i = 0;
            Object.keys(result).forEach(key => {
                themes[i] = { value: key, label: result[key] };
                i++;
            });
            setHasThemes(themes.length > 0);
            setEnableTema(true);

        } catch (err) {
            if (!err?.response) {
                console.log('Falha em aceder ao servidor');
            }
        }
    }

    const handleWaveChange = (selectedWave) => {
        setSelectedWave(selectedWave);
        setWaveName(selectedWave.label);
        trackPromise(getStudyTypes(selectedWave.label));
        limparTipoEstudo();
        setStudyTypesOptions(studyTypes);
    };



    const getLineOptions = async (isEvolutivo) => {
        const response = await axiosInstance.post(SOFTDATAWSURL + "/tables/getlineoptions",
            {},
            {
                params: {
                    username,
                    token,
                    studyName,
                    waveName,
                    isEvolutivo
                }
            });
        try {

            const result = await response.data;
            setLines([]);
            var i = 0;
            Object.keys(result).forEach(key => {
                lines[i] = { value: key, label: result[key] };
                i++;
            });
            setEnableLinha(true);

        } catch (err) {
            if (!err?.response) {
                console.log('Falha em aceder ao servidor');
            }
        }

    }

    const handleStudyTypeChange = (selectedStudyType) => {
        setSelectedStudyType(selectedStudyType);
        var evol=false;
        if (selectedStudyType.value === "simples") {
            evol=false;
        } else {
            evol=true;
        }
        limparTemas();
        limparLinhas();
        const promises = [];
        promises.push(getThemesOptions(evol));
        promises.push(getLineOptions(evol));
        Promise.all(promises)
            .then((result)=>{
                setThemesOptions(themes);
                setLinesOptions(lines);
                setIsEvolutivo(evol);
            })
            .catch((error)=>{
                console.log(error);
            });                
    };

    const getLineOptionsTheme = async (isevolutive, theme) => {


        const response = await axiosInstance.post(SOFTDATAWSURL + "/tables/getlineoptionstheme",
            {},
            {
                params: {
                    username,
                    token,
                    studyName,
                    waveName,
                    theme,
                    isevolutive
                }
            });
        try {


            const result = await response.data;
            setLines([]);
            var i = 0;
            Object.keys(result).forEach(key => {
                lines[i] = { value: key, label: result[key] };
                i++;
            });
            setEnableLinha(true);

        } catch (err) {
            if (!err?.response) {
                console.log('Falha em aceder ao servidor');
            }
        }

    }

    const handleThemeChange = (selectedTheme) => {
        setSelectedTheme(selectedTheme);
        setThemeName(selectedTheme.value);
        limparLinhas();
        trackPromise(getLineOptionsTheme(isEvolutivo, selectedTheme.value));
        setLinesOptions(lines);
    };

    const getColumnOptions = async (lineName) => {

        const response = await axiosInstance.post(SOFTDATAWSURL + "/tables/getcolumnoptions",
            {},
            {
                params: {
                    username,
                    token,
                    studyName,
                    waveName,
                    lineName
                }
            });

        try {

            const result = await response.data;
            setColumns([]);
            var i = 0;
            Object.keys(result).forEach(key => {
                columns[i] = { value: key, label: result[key] };
                i++;
            });
            setEnableColuna(true);

        } catch (err) {
            if (!err?.response) {
                console.log('Falha em aceder ao servidor');
            }
        }
    }



    const getVagasEvolutivosOptions = async (lineName) => {

        const response = await axiosInstance.post(SOFTDATAWSURL + "/tables/getvagasevolutivosoptions",
            {},
            {
                params: {
                    username,
                    token,
                    studyName,
                    waveName,
                    lineName
                }
            });

        try {

            const result = await response.data;
            var i = 0;
            setVagasEvolutivos([]);
            await result.forEach(element => {
                vagasEvolutivos[i] = { value: element, label: element };
                i++;
            });
            setEnableVagasEvolutivos(true);

        } catch (err) {
            if (!err?.response) {
                console.log('Falha em aceder ao servidor');
            }
        }
    }

    const getAggregatorOptions = async (columnName, isEvolutive) => {

        const response = await axiosInstance.post(SOFTDATAWSURL + "/tables/getaggregatoroptions",
            {},
            {
                params: {
                    username,
                    token,
                    studyName,
                    waveName,
                    lineName,
                    columnName,
                    isEvolutive
                }
            });

        try {

            const result = await response.data;
            setAggregators([]);
            var i = 0;
            Object.keys(result).forEach(key => {
                aggregators[i] = { value: key, label: result[key] };
                i++;
            });

        } catch (err) {
            if (!err?.response) {
                console.log('Falha em aceder ao servidor');
            }
        }
    }

    const handleLineChange = (selectedLine) => {
        setSelectedLine(selectedLine);
        setLineName(selectedLine.value);
        limparColunasVagas();
        if (!isEvolutivo) {
            trackPromise(getColumnOptions(selectedLine.value));
            setColumnsOptions(columns);
        } else {
            trackPromise(getVagasEvolutivosOptions(selectedLine.value));
            setVagasEvolutivosOptions(vagasEvolutivos);
        }

    };

    const handleColumnChange = (selectedColumn) => {
        limparFiltros();
        setSelectedColumn(selectedColumn);
        setColumnName(selectedColumn.value);
        setColumnLabel(selectedColumn.label);
        trackPromise(getAggregatorOptions(selectedColumn.value, false));
        setAggregatorsOptions(aggregators);
        setShowButtons(true);
    }

    const handleVagasEvolutivos = (selectedVagaEvolutivosvagasEvolutivos) => {
        limparFiltros();
        setSelectedVagasEvolutivo(Array.isArray(selectedVagaEvolutivosvagasEvolutivos) ? selectedVagaEvolutivosvagasEvolutivos.map(x => x.value) : []);
        const totalVagas = selectedVagasEvolutivo.length;
        setTotalVagasEvolutivo(totalVagas + 1);
        trackPromise(getAggregatorOptions(null, true));
        setAggregatorsOptions(aggregators);
        setShowButtons(true);
    }

    const getAggregatorLineOptions = async (aggregatorName, aggregatorLabel) => {

        const response = await axiosInstance.post(SOFTDATAWSURL + "/tables/getaggregatorlineoptions",
            {},
            {
                params: {
                    username,
                    token,
                    studyName,
                    waveName,
                    aggregatorName
                }
            });

        try {
            const responseStatus = await response.status;

            const result = await response.data;
            setAggregatorLines([]);
            var i = 0;
            Object.keys(result).forEach(key => {
                aggregatorLines[i] = { value: `aggregatorID: "${aggregatorName}", aggregatorName: "${aggregatorLabel}", aggregatorValue: "${result[key]}"`, label: `${aggregatorLabel}: ${result[key]}` };
                i++;
            });

        } catch (err) {
            if (!err?.response) {
                console.log('Falha em aceder ao servidor');
            }
        }
    }


    const handleAggregatorChange = (selectedAggregator) => {
        setSelectedAggregator(selectedAggregator);
        setAggregatorID(selectedAggregator.value);
        setAggregatorName(selectedAggregator.label);
        trackPromise(getAggregatorLineOptions(selectedAggregator.value, selectedAggregator.label));
        setAggregatorsLineOptions(aggregatorLines);

    }

    const handleAggregatorLineChange = (selectedAggregatorLine) => {
        var value = selectedAggregatorLine.map(x => x.value);

        setFilters(Array.isArray(selectedAggregatorLine) ? value : []);
    }

    const limparVagasEstudo = () => {
        setWavesOptions([]);
        setSelectedWave(wavesDefaultOption);
        limparTipoEstudo();
        setIsEvolutivo(false);
        setEnableVaga(false);
    }

    const limparVariaveis = () => {
        setSelectedStudyType(studyTypesDefaultOption);
        if (hasThemes) {
            limparTemas();
        } else {
            limparLinhas();
        }
        setHasThemes(false);
    }

    const limparTipoEstudo = () => {
        setStudyTypesOptions([]);
        limparVariaveis();
        setEnableTipoEstudo(false);
    }

    const limparTemas = () => {
        setThemesOptions([]);
        setSelectedTheme(themesDefaultOption);
        setHasThemes(false);
        limparLinhas();
        setEnableTema(false);
    }

    const limparLinhas = () => {
        setLinesOptions([]);
        setSelectedLine(linesDefaultOption);
        limparColunasVagas();
        setEnableLinha(false);
    }

    const limparColunasVagas = () => {
        limparFiltros();
        if (!isEvolutivo) {
            setColumnsOptions([]);
            setSelectedColumn(columnsDefaultOption);
            setEnableColuna(false);
        } else {
            setVagasEvolutivosOptions([]);
            setVagasEvolutivos([]);
            setTotalVagasEvolutivo(0);
            setSelectedVagasEvolutivo([]);
            selectVagasEvolutivosRef.current.clearValue();
            setEnableVagasEvolutivos(false);
        }
        setShowButtons(false);
    }

    const limparFiltros = () => {
        setAggregatorsOptions([]);
        setSelectedAggregator(aggregatorsDefaultOption);
        setAggregatorsLineOptions([]);
        setSelectedAggregatorLines([]);
        setFilters([]);
        setShowFilter(false);
        setData(null);
        setShowSimpleStudy(false);
        setShowEvolutiveStudy(false);
    }

    const handleLimparVariaveis = () => {
        limparVariaveis();
        setData(null);
        setHasData(false);
        setShowSimpleStudy(false);
        setShowEvolutiveStudy(false);
    }

    const handleMostrarResultados = () => {
        setShowSimpleStudy(false);
        setShowEvolutiveStudy(false);
        if (!isEvolutivo) {
            setShowSimpleStudy(true);
        } else {
            setShowEvolutiveStudy(true);
        }
    }

    return (
        <div style={{ padding: 10, color: "white", backgroundColor: "#6B747F" }}>
            <table style={{ width: "100%" }}>
                <tr>
                    <td style={{ width: "20%", verticalAlign: "top", paddingTop: "2px", paddingBottom: "2px" }}>
                        <nav>
                            <ul>
                                <div style={{ padding: 5 }}>
                                    <h6>Estudo</h6>
                                    <Select
                                        placeholder="Estudo"
                                        onChange={handleStudyChange}
                                        value={selectedStudy}
                                        options={studiesOptions}
                                        styles={colourStyles}
                                        isClearable={false}
                                    />
                                </div>
                                <div style={{ padding: 5 }}>
                                    <h6>Vaga</h6>
                                    <Select
                                        isDisabled={!enableVaga}
                                        placeholder="Vaga"
                                        value={selectedWave}
                                        onChange={handleWaveChange}
                                        options={wavesOptions}
                                        styles={colourStyles}
                                        cacheOptions={false}
                                    />
                                </div>
                                <div style={{ padding: 5 }}>
                                    <h6>Tipo de estudo</h6>
                                    <Select
                                        isDisabled={!enableTipoEstudo}
                                        placeholder="Tipo"
                                        value={selectedStudyType}
                                        onChange={handleStudyTypeChange}
                                        options={studyTypesOptions}
                                        styles={colourStyles}
                                        cacheOptions={false}
                                    />
                                </div>
                                {hasThemes &&
                                    <div style={{ padding: 5 }}>
                                        <h6>Tema</h6>
                                        <Select
                                            isDisabled={!enableTema}
                                            placeholder="Tema"
                                            defaultValue={selectedTheme}
                                            onChange={handleThemeChange}
                                            options={themesOptions}
                                            styles={colourStyles}
                                            cacheOptions={false}
                                        />
                                    </div>
                                }
                                <div style={{ padding: 5 }}>
                                    <h6>Variável em linha</h6>
                                    <Select
                                        isDisabled={!enableLinha}
                                        placeholder="Variável em linha"
                                        value={selectedLine}
                                        onChange={handleLineChange}
                                        options={linesOptions}
                                        styles={colourStyles}
                                        cacheOptions={false}
                                    />
                                </div>
                                {!isEvolutivo &&
                                    <div style={{ padding: 5 }}>
                                        <h6>Variável em coluna</h6>
                                        <Select
                                            isDisabled={!enableColuna}
                                            placeholder="Variável em coluna"
                                            value={selectedColumn}
                                            onChange={handleColumnChange}
                                            options={columnsOptions}
                                            styles={colourStyles}
                                            cacheOptions={false}
                                        />
                                    </div>}
                                {isEvolutivo &&
                                    <div style={{ padding: 5 }}>
                                        <h6>Vagas para evolutivos</h6>
                                        <Select
                                            ref={selectVagasEvolutivosRef}
                                            isDisabled={!enableVagasEvolutivos}
                                            onChange={handleVagasEvolutivos}
                                            placeholder="Vagas para evolutivos"
                                            isMulti={true}
                                            closeMenuOnSelect={false}
                                            components={animatedComponents}
                                            options={totalVagasEvolutivo === maxVagasEvolutivos ? [] : vagasEvolutivosOptions}
                                            noOptionsMessage={() => {
                                                return totalVagasEvolutivo === maxVagasEvolutivos
                                                    ? 'Só pode selecionar até 7 vagas'
                                                    : 'Não existem mais vagas para selecionar';
                                            }}
                                            styles={colourStyles}
                                            cacheOptions={false}
                                        />
                                        <span style={{ fontSize: 10 }}>* Selecione no máximo 7 vagas.</span>
                                    </div>}
                                {showFilter && <div style={{ padding: 5 }}>
                                    <h5><BsFilterCircleFill />&nbsp;Filtros</h5>
                                </div>}
                                {showFilter &&
                                    <div style={{ padding: 5 }}>
                                        <h6>Variáveis</h6>
                                        <Select
                                            value={selectedAggregator}
                                            options={aggregatorsOptions}
                                            onChange={handleAggregatorChange}
                                            placeholder="variáveis para filtros"
                                            styles={colourStyles}
                                            cacheOptions={false}
                                        />
                                    </div>}
                                {showFilter &&
                                    <div style={{ padding: 5 }}>
                                        <h6>Opções de filtragem</h6>
                                        <Select
                                            options={aggregatorLineOptions}
                                            closeMenuOnSelect={false}
                                            isMulti={true}
                                            components={animatedComponents}
                                            onChange={handleAggregatorLineChange}
                                            placeholder="opções de filtragem"
                                            styles={colourStyles}
                                            cacheOptions={false}
                                            noOptionsMessage={() => {
                                                return 'Não mais existem opções';
                                            }}
                                        />
                                    </div>
                                }
                                {
                                    showButtons &&
                                    <div>
                                        <div align="center">
                                            <button style={{ width: "170px", height: "50px", fontSize: "14px" }} onClick={handleMostrarResultados}><BsFileEarmarkBarGraphFill />&nbsp;Mostrar resultados</button>
                                        </div>
                                        <div align="center">
                                            <button style={{ width: "170px", height: "50px", fontSize: "14px" }} onClick={() => setShowFilter(showFilter => !showFilter)}><BsFilter />&nbsp;{!showFilter ? 'Mostrar filtros' : 'Ocultar filtros'} </button>
                                        </div>

                                    </div>
                                }

                                <div align="center">
                                    <button style={{ width: "170px", height: "50px", fontSize: "14px" }} onClick={handleLimparVariaveis}><BsFillTrashFill />&nbsp;Limpar variáveis</button>
                                </div>


                            </ul>
                        </nav>
                    </td>

                    <td colspan={2} style={{ width: "100%", verticalAlign: "top", paddingTop: "2px", paddingBottom: "2px" }}>
                        {showSimpleStudy && <div>
                            <SimpleStudytData
                                username={username}
                                token={token}
                                studyName={studyName}
                                waveName={waveName}
                                lineName={lineName}
                                columnName={columnName}
                                columnLabel={columnLabel}
                                filters={filters}
                                authContext={authContext}
                            />
                        </div>}
                        {showEvolutiveStudy && <div>
                            <EvolutiveStudytData
                                username={username}
                                token={token}
                                studyName={studyName}
                                waveID={selectedWave.value}
                                waveName={waveName}
                                lineName={lineName}
                                selectedVagasEvolutivo={selectedVagasEvolutivo}
                                filters={filters}
                            />
                        </div>}
                        {!showSimpleStudy && !showEvolutiveStudy && selectedStudy.value === "" && <div>
                            <TablesNoData
                                title1=""
                                title2="Benvindo ao SoftData Tables."
                                title3="Por favor selecione o estudo no canto superior esquerdo." />
                        </div>}
                        {!showSimpleStudy && !showEvolutiveStudy && selectedStudy.value !== "" && <div>
                            <TablesNoData
                                title1=""
                                title2=""
                                title3="" />
                        </div>}

                    </td>
                </tr>
            </table>
        </div>
    );
};
export default Navigation;