import React from 'react';
import Excel from 'exceljs';
import { saveAs } from 'file-saver';
import { RiFileExcel2Fill } from "react-icons/ri";
import GetFiltrosText from '../Tables/GetFiltrosText';

const workSheetName = 'Softdata';

const TableToExcel = (props) => {
  const title1 = props.title1;
  const title2 = props.title2;
  const columnNames = props.columns;
  const data = props.rowData;
  const textoBase = props.textoBase;
  const textoNota = props.textoNota;
  const topColumn = props.topColumn;
  const dataTypeName = props.dataTypeName;
  var evolutivo = props.evolutivo;
  var hasTotalColumn = true;
  const filtrosArray = props.filtrosArray;

  if (typeof evolutivo==='undefined'){
    evolutivo = false;
  }
  if (typeof data[0].totalCol === 'undefined'){
    hasTotalColumn = false;
  }

  const workbook = new Excel.Workbook();
  
  const saveExcel = async () => {
    try {
      //Build Header
      var columns = [
        { header: '', key: 'lineName' }
        
      ];
      if (hasTotalColumn){
        columns[1] = { header: 'Total', key: 'totalCol' };
      }
      var startIndex;
      if (hasTotalColumn){
        startIndex=2;
      } else {
        startIndex=1;
      }
      for (let i = 0; i < columnNames.length; i++) {     
        var colTitle = columnNames[i].title;
        colTitle = colTitle.replaceAll("&nbsp;"," ");
        colTitle = colTitle.replaceAll("<BR/>"," ");
        columns[i + startIndex] = { header: colTitle, key: columnNames[i].field };
      }

      var totalLines=0;
      var totalCols=columns.length;

      const fileName = "Softdata";

      // creating one worksheet in workbook
      const worksheet = workbook.addWorksheet(workSheetName);

      // add worksheet columns
      // each columns contains header and its mapping key from data
      worksheet.columns = columns;

      // updated the font for first row.
      worksheet.getRow(1).font = { bold: true };
      // loop through all of the columns and set the alignment with width.
      /*worksheet.columns.forEach(column => {
        column.width = column.header.length + 5;
        column.alignment = { horizontal: 'left' };
      });*/
      worksheet.columns.forEach((column) => {
        let maxLength = 0;
        column.eachCell({ includeEmpty: true }, (cell) => {
          const cellValue = cell.value ? cell.value.toString() : "";
          maxLength = Math.max(maxLength, cellValue.length);
        });
        column.width = maxLength + 2; // Adding padding
      });
      totalLines++;

      // loop through data and add each one to worksheet
      data.forEach(singleData => {
        worksheet.addRow(singleData);
        totalLines++;
      });
      var linesIndexAux=0;
      var colsIndexAux;
      // loop through all of the rows and set the outline style.
      worksheet.eachRow({ includeEmpty: false }, row => {
        // store each cell to currentCell
        linesIndexAux++;
        const currentCell = row._cells;
        colsIndexAux=0;
        // loop through currentCell to apply border only for the non-empty cell of excel
        currentCell.forEach(singleCell => {
          colsIndexAux++;
          // store the cell address i.e. A1, A2, A3, B1, B2, B3, ...
          const cellAddress = singleCell._address;
          if (colsIndexAux>1 && linesIndexAux>1){            
            var number = singleCell.value;
            if (number.includes(",")){
              number = number.replace(",",".");             
            } else {
              number=parseFloat(number.replace(/[^0-9.-]/g, ''));
            }
            singleCell.value=+number;
          }
          // apply border
          worksheet.getCell(cellAddress).border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
          };
        });
      });
      //Nome Grupo Colunas
      var topRow;
      if (hasTotalColumn){
        topRow=worksheet.insertRow(1,['','Total',topColumn],'n');
      } else {
        topRow=worksheet.insertRow(1,['',topColumn],'n');
      }
      
      
      worksheet.getRow(1).font = { bold: true };      
      const groupColumns = topRow._cells;
      groupColumns.forEach(singleCell => {
        // store the cell address i.e. A1, A2, A3, B1, B2, B3, ...
        const cellAddress = singleCell._address;

        // apply border
        worksheet.getCell(cellAddress).border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' }
        };
      });      
      if (hasTotalColumn){
        worksheet.getCell('C1').alignment = {horizontal: 'center'};
      } else {
        worksheet.getCell('B1').alignment = {horizontal: 'center'};
      }
      const columnsCells = worksheet.getRow(2)._cells;
      columnsCells.forEach(singleCell => {
        const cellAddress = singleCell._address;
        worksheet.getCell(cellAddress).alignment = {horizontal: 'center'};
      });

      totalLines++;
      //Linha Vazia
      topRow=worksheet.insertRow(1,[''],'n');
      totalLines++;
      //Linha Nome Quadro
      topRow=worksheet.insertRow(1,[title2],'n');
      worksheet.getRow(1).font = { bold: true };
      worksheet.getCell('A1').alignment = {horizontal: 'left'};
      totalLines++;

      //Linha Nome Estudo
      topRow=worksheet.insertRow(1,[title1],'n');
      worksheet.getRow(1).font = { bold: true };
      worksheet.getCell('A1').alignment = {horizontal: 'left'};
      totalLines++;
      //Merge titulo1
      worksheet.mergeCells(1,1,1,20);
      //Merge titulo2
      worksheet.mergeCells(2,1,2,20);
      //Merge grupo de colunas
      if (hasTotalColumn){
        worksheet.mergeCells(4,3,4,totalCols);
      } else {
        worksheet.mergeCells(4,2,4,totalCols);
      }
      //Merge coluna linha
      worksheet.mergeCells(4,1,5,1);
      //Merge coluna Total
      if (hasTotalColumn){
        worksheet.mergeCells(4,2,5,2);
      }
      
      worksheet.addRow({lineName: ""});
      totalLines++;
      
      if (typeof textoBase !=='undefined' && textoBase!==""){
        worksheet.addRow({lineName: "Base: "+textoBase.replace(/(<([^>]+)>)/ig, '')});
        totalLines++;
        worksheet.mergeCells(totalLines,1,totalLines,20);
        worksheet.addRow({lineName: ""});
        totalLines++;
      }
      if (typeof textoNota !=='undefined' && textoNota!==""){
        worksheet.addRow({lineName: "Nota: "+textoNota.replace(/(<([^>]+)>)/ig, '')});
        totalLines++;        
        worksheet.mergeCells(totalLines,1,totalLines,20);        
        worksheet.addRow({lineName: ""});
        totalLines++;
      }

      if (filtrosArray.length>0){
        const filtrosText = GetFiltrosText(filtrosArray);
        worksheet.addRow({lineName: "Filtros: "+filtrosText});
        totalLines++;
        worksheet.mergeCells(totalLines,1,totalLines,20);
        worksheet.addRow({lineName: ""});
        totalLines++;
      }

      worksheet.addRow({lineName: dataTypeName});
      worksheet.mergeCells(totalLines,1,totalLines,20); 
      totalLines++;           
      worksheet.addRow({lineName: ""});
      worksheet.mergeCells(totalLines,1,totalLines,20);
      totalLines++;
      var currDate = new Date();
      worksheet.addRow({lineName: "(c) "+currDate.getFullYear()+" DATA E - todos os direitos reservados"});
      totalLines++;
      worksheet.mergeCells(totalLines,1,totalLines,20);        
      const firstColumn = worksheet.getColumn(1); // 1 refers to column 'A'
      let maxLengthFirstCol = 0;
      firstColumn.eachCell({ includeEmpty: true }, (cell) => {
          const cellValue = cell.value ? cell.value.toString() : "";
          maxLengthFirstCol = Math.max(maxLengthFirstCol, cellValue.length);        
      });        
      firstColumn.width = maxLengthFirstCol + 2; // Adding padding

      // write the content using writeBuffer
      const buf = await workbook.xlsx.writeBuffer();

      // download the processed file
      saveAs(new Blob([buf]), `${fileName}.xlsx`);
    } catch (error) {
      console.error('<<<ERRROR>>>', error);
      console.error('Something Went Wrong', error.message);
    } finally {
      // removing worksheet's instance to create new one
      workbook.removeWorksheet(workSheetName);
    }
  };

  return (
    <div style={{textAlign: "center"}}>
        <RiFileExcel2Fill
          onClick={saveExcel}
          style={{ fontSize: "32px" }}
          title="Exportar para Excel"
          onMouseOver={({ target }) => target.style.color = "#515860"}
          onMouseOut={({ target }) => target.style.color = "black"}
        />
    </div>

  );

}

export default TableToExcel;